import React from 'react'

import '../components/bootstrap.min.css';
import '../components/vendor/tether/tether.min.css';
import '../components/vendor/bootstrap/css/bootstrap.min.css';
import '../components/css/fonts/express-icons.css';
import '../components/css/card.css';

import '../components/vendor/font-awesome/css/font-awesome.min.css';
import '../components/vendor/bootstrap/css/glyphicon.css';
import '../components/vendor/cubeportfolio/css/cubeportfolio.min.css';

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import '../components/vendor/owl-carousel/owl.theme.css';
import '../components/vendor/owl-carousel/owl.carousel.css';
import '../components/vendor/magnific-popup/magnific-popup.css';
import '../components/vendor/lite-tooltip/css/litetooltip.css';
import '../components/vendor/PricingTablesInspiration/css/component.css';
import '../components/vendor/PricingTablesInspiration/css/icons.css';
import '../components/vendor/PricingTablesInspiration/css/demo.css';
//Theme CSS
import '../components/css/main.css';
import '../components/css/main-shortcodes.css';
import '../components/css/header.css';
import '../components/css/form-element.css';
import '../components/css/animation.css';
import '../components/css/bg-stylish.css';
import '../components/css/blog.css';
import '../components/css/streamline-icon.css';
import '../components/css/responsive.css';
import '../components/css/utilities.css';
import '../components/css/portfolio.css';
import '../components/css/font-icons.css';
import '../components/css/skins/default.css';

//Theme Custom CSS
import '../components/css/custom.css';

import Footer from "../components/footer";
import Header from "../components/header";

const IndexPage = () => (
<div class="wrapper">
<Header/>
<div class="page">
<div class="page-inner p-none no-border">

<section class="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall" style={{backgroundImage:"url(/img/custom/c30.jpg)"}}>

   <div class="container pt-120 pb-120">
      <div class="row">
         <div class="col-sm-12">
            <h1 class="section-title">
               Introducing <strong>Express</strong> Today. A new way to start an <br />
               Easy Design Bootstrap Template
            </h1>

            <div class="clearfix pt-xl">
               <a href="#" class="btn btn-primary mr-md"><i class="fa fa-heart"></i> <strong class="text-white">Download Now</strong></a>
               <a href="#" class="btn btn-default"><i class="fa fa-heart"></i> Purchase<strong> Now</strong></a>
            </div>
         </div>
      </div>
   </div>
</section>




<section class="section-big main-color right-top-br bg-ps-left" style={{backgroundImage:"url(/img/banner/s2.jpg)",backgroundSize:"cover"}}>
   <div class="container ">
      <div class="row">
         <div class="col-md-6">
         </div>

         <div class="col-sm-5 offset-md-1">
            <h1 class="section-title">
               Design for Startups
            </h1>
            <div class="exp-separator">
               <div class="exp-separator-inner">
               </div>
            </div>

            <p>
               Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
               consequat vitae, eleifend Aliquam lorem ante, dapibus in.
               Class aptent taciti sociosqu ad litora torquent per conubia nostra,
               donate inceptos himenaeos. Nulla nunc dui, tristique in semper.
            </p>

            <a href="#" class="btn btn-primary btn-rounded">Contact Us Today</a>
         </div>
      </div>
   </div>
</section>

<section class="section-big main-color bg-ps-right left-top-br" style={{backgroundImage:"url(/img/banner/s3.jpg)",backgroundSize:"cover"}}>
   <div class="container ">
      <div class="row">

         <div class="col-sm-5 offset-md-1">
            <h1 class="section-title">
               Bootstrap UI Development
            </h1>
            <div class="exp-separator">
               <div class="exp-separator-inner">
               </div>
            </div>

            <p>
               Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
               consequat vitae, eleifend Aliquam lorem ante, dapibus in.
               Class aptent taciti sociosqu ad litora torquent per conubia nostra,
               donate inceptos himenaeos. Nulla nunc dui, tristique.
            </p>

            <a href="#" class="btn btn-primary btn-rounded">Contact Us Today</a>
         </div>
      </div>
   </div>
</section>

<section class="section-big main-color right-top-br bg-ps-left" style={{backgroundImage:"url(/img/banner/s4.jpg)",backgroundSize:"cover"}}>
   <div class="container ">
      <div class="row">
         <div class="col-md-6">
         </div>

         <div class="col-sm-5 offset-md-1">
            <h1 class="section-title">
               Teknoloji & Service
            </h1>
            <div class="exp-separator">
               <div class="exp-separator-inner">
               </div>
            </div>

            <p>
               Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
               consequat vitae, eleifend Aliquam lorem ante, dapibus in.
               Class aptent taciti sociosqu ad litora torquent per conubia nostra,
               donate inceptos himenaeos. Nulla nunc dui, tristique.
            </p>

            <a href="#" class="btn btn-primary btn-rounded">Contact Us Today</a>
         </div>
      </div>
   </div>
</section>
<section class="section-big main-color bg-ps-right left-top-br" style={{backgroundImage:"url(/img/banner/s5.png)"}}>
   <div class="container ">
      <div class="row">

         <div class="col-md-5 offset-md-1">
            <h1 class="section-title">
               Easy To Development
            </h1>
            <div class="exp-separator">
               <div class="exp-separator-inner">
               </div>
            </div>

            <p>
               Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
               consequat vitae, eleifend Aliquam lorem ante, dapibus in.
               Class aptent taciti sociosqu ad litora torquent per conubia nostra,
               inceptos himenaeos. Nulla nunc dui, tristique in semper vel.
            </p>

            <a href="#" class="btn btn-primary btn-rounded">Contact Us Today</a>
         </div>
      </div>
   </div>
</section>

<section class="section-big main-color">
   <div class="container">

      <div class="row">
         <div class="col-md-12 pb-20 text-center"> 
            <h2 class="section-title mb-10"><span> Some <strong class="primary-color">Express</strong> Core Featuress </span></h2>
           <p class="section-sub-title">
               We are a passionate digital design agency that specializes in beautiful and easy-to- <br/>
               use digital design &amp; web development services.
            </p>
            <div class="exp-separator center-separator">
               <div class="exp-separator-inner">
               </div>
            </div>

         </div>
      </div>

      <div class="row">
         <div class="col-md-4">
            <ul class="i-list medium">
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-desktop"></i> </div>

                  <div class="icon-content">
                     <h3 class="title">Fully Responsive Design</h3>
                     <p class="sub-title">
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend ac, enim ante, dapibus in.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-code"></i> </div>

                  <div class="icon-content">
                     <h3 class="title">Easy & Clean Code</h3>
                     <p>
                        Aenean vulputate tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-paper-plane"></i> </div>

                  <div class="icon-content">
                     <h3 class="title">24/7 Customer Support</h3>
                     <p>
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat, eleifend ac, enim lorem ante, dapibus in.
                     </p>
                  </div>
               </li>
            </ul>
         </div>
         <div class="col-md-4">
            <ul class="i-list medium">
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-diamond"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Endless Possibilites</h3>
                     <p class="sub-title">
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend Aliquam lorem ante, dapibus in.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-recycle"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Free Lifetime Updates</h3>
                     <p>
                        Aenean eleifend tellus. Aenean leo ligula, porttitor eu
                        consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-check"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Clean & Modern Design</h3>
                     <p>
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend enim lorem ante, dapibus in.
                     </p>
                  </div>
               </li>
            </ul>
         </div>
         <div class="col-md-4">
            <ul class="i-list medium">
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-codepen"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Useful Shortcodes</h3>
                     <p class="sub-title">
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend ac, enim. Aliquam lorem ante.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-newspaper-o"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Multipurpose Concept</h3>
                     <p>
                        Aenean vulputate eleifend tellus ligula, porttitor eu,
                        consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in.
                     </p>
                  </div>
                  <div class="iconlist-timeline"></div>
               </li>
               <li class="i-list-item">
                  <div class="icon"> <i class="fa fa-heart-o"></i> </div>
                  <div class="icon-content">
                     <h3 class="title">Crafted With Love</h3>
                     <p>
                        Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu,
                        consequat vitae, eleifend ac lorem ante, dapibus in.
                     </p>
                  </div>
               </li>
            </ul>
         </div>
      </div>
   </div>
</section>

<section class="main-color">
   <div class="container">
      <div class="row">
         <div class="col-md-6">

            <table class="clients-wrap v4">
               <tbody>
                  <tr>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="Dribbble" data-location="top">
                              <img src="/img/clients/amcor.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="VmWare" data-location="top">
                              <img src="/img/clients/audiopro.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="JQuery" data-location="top">
                              <img src="/img/clients/Infinity.png" />
                           </a>
                        </figure>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="VimeoPRO" data-location="top">
                              <img src="/img/clients/swann.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="Demond" data-location="top">
                              <img src="/img/clients/rko.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="Dribbble" data-location="top">
                              <img src="/img/clients/Sport.png" />
                           </a>
                        </figure>
                     </td>
                  </tr>
                  <tr>
                     <td>
                        <figure>
                           <a class="lite-tooltip" href="#" data-title="JQuery" data-location="top">
                              <img src="/img/clients/swann.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a href="https://vimeo.com/awards" target="_blank">
                              <img src="/img/clients/audiopro.png" />
                           </a>
                        </figure>
                     </td>
                     <td>
                        <figure>
                           <a href="https://vimeo.com/awards" target="_blank">
                              <img src="/img/clients/rko.png" />
                           </a>
                        </figure>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>

         <div class="col-md-6 pr-xl">

            <h3 class="uppercase fs-18 o-4 ls-1">Express</h3>
            <h2 class="section-title">
               World Famous Brands
            </h2>
            <p class=" mt-md">
               orem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean <br />
               commodo ligula eget dolor. Aenean massa. Cum sociis natoque nascetur ridiculus mus.
               commodo ligula eget dolor
            </p>
            <p>
               Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
               Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque nascetur ridiculus mus.
               Lorem ipsum dolor sit amet, adipiscing elit aenean <br />
               Aenean commodo ligula eget dolor.  massa.
            </p>

            <a href="#" class="btn btn-primary mt-md mr-md"><i class="fa fa-info"></i> Learn about Express</a>
         </div>
      </div>
   </div>
</section>
<section class="section-primary main-color">
   <div class="container">
      <div class="row">
         <div class="col-md-12">

            <section class="cta cta-shadow-3x cta-dark mb-xl">
               <div class="cta-inner p-0 pr-45">
                  <div class="cta-content">

                     <img src="/img/custom/sales.png" alt="sales and specials" title="sales and specials"/>

                     <h3 class="d-inline-block fs-20 fw-3">High quality templates for your next web project.</h3>
                  </div>
                  <div class="cta-btn">
                     <a href="https://wrapbootstrap.com/theme/express-responsive-bootstrap-template-WB0TR1711" target="_blank" class="btn standard transparent-light text-white">
                        Purchase Now! <i class="fa fa-angle-right fs-16 fw-6"></i>
                     </a>
                  </div>
               </div>
            </section>
         </div>
      </div>
   </div>
</section>
</div>

</div>
<Footer/>
</div>
)

export default IndexPage
